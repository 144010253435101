import React from 'react'
import PropTypes from 'prop-types'
import { GridWrapper, GridItem } from '@jsluna/grid'
import * as Constants from 'utils/constants'
import NothingToSee from '../Messages/NothingToSee'
import TodayItems from './TodayItems'
import FailedToFetch from '../ErrorPages/FailedToFetch'

export const Content = ({
  hasNoActivities,
  hasError,
  items,
  section,
  isMobile
}) => (
  <GridWrapper horizontalAlign="center" id="activities-content-container">
    <GridItem
      size="1/1"
      className="ln-u-margin-top*3"
      style={{ padding: !isMobile && 0 }}
    >
      {section === Constants.TODAY && (
        <TodayItems items={items} isMobile={isMobile} />
      )}
      {section === Constants.GOLTASKS && (
        <TodayItems items={items} isMobile={isMobile} />
      )}
      {hasError && <FailedToFetch />}
      {hasNoActivities && <NothingToSee />}
    </GridItem>
  </GridWrapper>
)

Content.propTypes = {
  hasNoActivities: PropTypes.bool,
  hasError: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  section: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired
}

Content.defaultProps = {
  hasNoActivities: true
}

export default Content
