import React from 'react'
import PropTypes from 'prop-types'
import { setIsMobile, setCurrentAzureUser } from 'actions'
import { Container } from '@jsluna/grid'
import { Tutorial } from '@jsainsburyplc/retail-stateful-tutorial'
import { withRouter } from 'react-router'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as Constants from 'utils/constants'
import { forceStoreSelection } from 'utils/currentStore'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'
import Header from './Header'
import ViewActivity from './Areas/ViewActivity/ViewActivity'
import LogOut from './Areas/LogOut/LogOut'
import ActivitiesWithFilter from './Areas/Activities/ActivitiesWithFilter'
import ChangeStore from './Areas/ChangeStore'
import WeeklyGroupedActivitiesWithFilters from './Areas/WeeklyGroupedActivities/WeeklyGroupedActivitiesWithFilters'
import ErrorPages from './Areas/ErrorPages'
import PageNotFound from './Areas/ErrorPages/PageNotFound'
import RiskCompliance from './Areas/RiskCompliance/RiskCompliance'
import Notifications from './Notifications/Notifications'
import ConnectionDropped from './Areas/Connection/ConnectionDropped'
import PlanningWithFilters from './Areas/Planning/PlanningWithFilters'
import SearchPage from './Areas/SearchPage'
import RiskPageCheckpoint from './Areas/Risk/RiskPageCheckpoint'
import FilterHydrator from './Areas/Filtering/Storage/FilterHydrator'
import tutorialFetch from '../utils/tutorialFetch'
import Audience from './Areas/Audience/Audience'
import { acquireTokenSilentRequest } from '../authentication/actions'
import { Scopes } from '../authentication/authConfig'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.setIsMobile = this.setIsMobile.bind(this)
    this.setInitializeAuthState = this.setInitializeAuthState.bind(this)
  }

  componentDidMount() {
    this.setIsMobile()
    this.setInitializeAuthState()
    this.props.setCurrentAzureUser(
      this.props.currentUser,
      this.props.currentUserId,
      this.props.currentUserEmail
    )

    window.addEventListener('resize', this.setIsMobile)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setIsMobile)
  }

  setIsMobile() {
    const { onSetIsMobile } = this.props
    onSetIsMobile(window.innerWidth <= 960)
  }

  setInitializeAuthState() {
    const { onSetInitializeAuthState } = this.props
    onSetInitializeAuthState(Scopes.ActivityLegacyApiReadWriteAll)
  }

  render() {
    const { currentUser, isAuthStateInitializationInProgress } = this.props
    const renderProgressIndicatorContent = () => (
      <ProgressIndicator
        className="ln-u-justify-content-center table-loader-indicator"
        loading
        preventFocus
      >
        <ProgressSpinner className="ln-u-push-right-sm" />
      </ProgressIndicator>
    )
    const renderAuthenticatedContent = () => (
      <div>
        <Tutorial
          project="activity"
          version={7}
          fetch={tutorialFetch}
          environment={Constants.ENVIRONMENT}
          notSkippable
        >
          <Header currentUser={currentUser} path={location.pathname} />
          <>
            <Audience />
            <FilterHydrator />
            <Container>
              <main id="main-content" className="ln-u-push-top-md">
                <Switch>
                  <Route path="/logout" component={LogOut} />
                  <Route path="/disconnected" component={ConnectionDropped} />
                  <Route
                    path="/viewactivity/:activityReference"
                    component={ViewActivity}
                  />
                  <Route path="/changestore" component={ChangeStore} />
                  <Route
                    path="/history/:page?"
                    render={props => (
                      <WeeklyGroupedActivitiesWithFilters {...props} />
                    )}
                  />
                  <Route path="/risk" component={RiskPageCheckpoint} />
                  <Route path="/risk-compliance" component={RiskCompliance} />
                  <Route path="/search/:searchText" component={SearchPage} />
                  <Route
                    exact
                    path="/today"
                    render={props => (
                      <ActivitiesWithFilter
                        section={Constants.TODAY}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/GolTasks"
                    render={props => (
                      <ActivitiesWithFilter
                        section={Constants.GOLTASKS}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/planning"
                    render={props => (
                      <PlanningWithFilters
                        section={Constants.PLANNING}
                        {...props}
                      />
                    )}
                  />
                  <Redirect exact from="/" to="/today" />
                  <Route path="/error/:errorCode" component={ErrorPages} />
                  <Route component={PageNotFound} />
                </Switch>
                <Notifications />
              </main>
            </Container>
          </>
        </Tutorial>
      </div>
    )
    return isAuthStateInitializationInProgress
      ? renderProgressIndicatorContent()
      : renderAuthenticatedContent()
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSetIsMobile: isMobile => {
      dispatch(setIsMobile(isMobile))
    },
    setCurrentAzureUser: (currentUsername, currentUserId, currentUserEmail) => {
      dispatch(
        setCurrentAzureUser(currentUsername, currentUserId, currentUserEmail)
      )
    },
    onSetInitializeAuthState: scope => {
      dispatch(acquireTokenSilentRequest(scope))
    }
  }
}

const mapStateToProps = state => ({
  currentUser: state.authentication.idTokenClaims?.name ?? '',
  currentUserId: state.authentication.idTokenClaims?.id ?? '',
  currentUserEmail: state.authentication.idTokenClaims?.email ?? '',
  isAuthStateInitializationInProgress:
    state.authentication.isAuthStateInitializationInProgress
})

App.propTypes = {
  currentUser: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
  currentUserEmail: PropTypes.string.isRequired,
  onSetIsMobile: PropTypes.func.isRequired,
  setCurrentAzureUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onSetInitializeAuthState: PropTypes.func.isRequired,
  isAuthStateInitializationInProgress: PropTypes.bool
}

App.defaultProps = {
  isAuthStateInitializationInProgress: true
}

export default withRouter(
  forceStoreSelection(connect(mapStateToProps, mapDispatchToProps)(App))
)
